define("db/routes/eras", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model() {
            var url = "https://mtm-server.herokuapp.com/eras";
            return $.getJSON(url);
        }
    });
});