define("db/routes/characters/name", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model(params) {
            var url = "https://mtm-server.herokuapp.com/characters/" + params.name;
            return $.getJSON(url);
        }
    });
});