define('db/controllers/characters/name', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        movie_selection: [],
        color_selection: [],
        outfit_selection: [],
        color_preview: "#eee",

        setupController: function () {
            this._super(...arguments);
            Ember.run.next(() => {
                const scrollContainer = Ember.$('.character-page');
                const scrollDurationMS = 1000;

                scrollContainer.animate({
                    scrollTop: 0
                }, scrollDurationMS);
            });
        },

        trigger: function () {
            Ember.run.scheduleOnce('afterRender', this, function () {
                var get_url = "https://mtm-server.herokuapp.com/movies";
                var me = this;
                $.get(get_url).then(function (response) {
                    me.movie_selection.pushObjects(JSON.parse(response));
                });
                var get_url = "https://mtm-server.herokuapp.com/colors";
                var me = this;
                $.get(get_url).then(function (response) {
                    var colors = [];
                    response = JSON.parse(response);
                    for (var i = 0; i < response.length; i++) {
                        colors.push({ "name": response[i].name,
                            "hex": response[i].hex });
                    }
                    console.log(colors);
                    me.color_selection.pushObjects(colors);
                });
            });
        }.on('init'),

        actions: {
            carouselScroll: function (anchor) {
                var anchored_div = document.getElementById(anchor);
                var anchored_parent = anchored_div.parentElement.parentElement;
                var scrollLocation = anchored_parent.scrollLeft;
                var scrollGoal = anchored_div.offsetLeft - anchored_parent.offsetLeft;
                console.log("Location ", scrollLocation, " Goal ", scrollGoal);
                var scrollDistance = scrollGoal - scrollLocation;
                anchored_parent.scrollLeft += scrollDistance;
            },

            getColors: function () {
                var get_url = "https://mtm-server.herokuapp.com/colors";
                var me = this;
                $.get(get_url).then(function (response) {
                    var colors = [];
                    response = JSON.parse(response);
                    for (var i = 0; i < response.length; i++) {
                        colors.push({ "name": response[i].name,
                            "hex": response[i].hex });
                    }
                    console.log(colors);
                    me.color_selection.pushObjects(colors);
                });

                this.toggleProperty("add_color");
            }

        }
    });
});