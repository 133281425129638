define('db/components/movie-info', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didInsertElement() {
            window.scrollTo(0, 0);
        }
    });
});