define('db/router', ['exports', 'db/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('colors', function () {
      this.route('color', { path: '/:color' });
    });

    this.route('characters', function () {
      this.route('name', { path: '/:name' });
    });
    this.route('movies', function () {
      this.route('movie', { path: '/:movie' });
    });
    this.route('welcome', { path: '/' });
    this.route('start-here');
    this.route('loading');
    this.route('200');
    this.route('mdb2022');
    this.route('random');
    this.route('eras', function () {
      this.route('era', { path: '/:id' });
    });
    this.route('franchises', function () {
      this.route('franchise', { path: '/:id' });
    });
    this.route('types', function () {
      this.route('type', { path: '/:id' });
    });
    this.route('marchbdc-guide');
  });

  exports.default = Router;
});