define("db/components/character-preview", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didInsertElement() {
            window.scrollTo(0, 0);
            var colors = this.element.getElementsByClassName("color");
            for (var i = 0; i < colors.length; i++) {
                var color = colors[i];
                color.style.backgroundColor = color.innerText;
                color.innerText = "";
            }
        }
    });
});