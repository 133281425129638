define("db/controllers/colors/color", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        importanceFilters: { "primary": true,
            "secondary": false,
            "accents": false },

        showPrimary: true,
        showSecondary: true,
        showAccents: true,

        actions: {

            togglePrimary() {
                this.toggleProperty('importanceFilters.primary');
                this.toggleProperty('showPrimary');

                var primary_colors = Array.from(document.getElementsByClassName("importance-3"));

                for (var color in primary_colors) {
                    console.log(color + ": " + primary_colors[color]);
                    if (this.showPrimary) {
                        try {
                            var atrs = primary_colors[color].getAttributeNames();
                            if (atrs.includes("style")) {
                                primary_colors[color].removeAttribute("style");
                            }
                        } catch (error) {
                            console.log("error");
                        }
                    } else {
                        try {
                            primary_colors[color].style.display = "none";
                        } catch (error) {
                            console.log("error");
                        }
                    }
                }
            },
            toggleSecondary() {
                this.toggleProperty('importanceFilters.secondary');
                this.toggleProperty('showSecondary');

                var colors = Array.from(document.getElementsByClassName("importance-2"));

                for (var color in colors) {
                    console.log(color + ": " + colors[color]);
                    if (this.showSecondary) {
                        try {
                            var atrs = colors[color].getAttributeNames();
                            if (atrs.includes("style")) {
                                colors[color].removeAttribute("style");
                            }
                        } catch (error) {
                            console.log("error");
                        }
                    } else {
                        try {
                            colors[color].style.display = "none";
                        } catch (error) {
                            console.log("error");
                        }
                    }
                }
            },
            toggleAccents() {
                this.toggleProperty('importanceFilters.accents');
                this.toggleProperty('showAccents');

                var colors = Array.from(document.getElementsByClassName("importance-1"));

                for (var color in colors) {
                    console.log(color + ": " + colors[color]);
                    if (this.showAccents) {
                        try {
                            var atrs = colors[color].getAttributeNames();
                            if (atrs.includes("style")) {
                                colors[color].removeAttribute("style");
                            }
                        } catch (error) {
                            console.log("error");
                        }
                    } else {
                        try {
                            colors[color].style.display = "none";
                        } catch (error) {
                            console.log("error");
                        }
                    }
                }
            }
        }
    });
});