define("db/routes/franchises/franchise", ["exports"], function (exports) {
     "use strict";

     Object.defineProperty(exports, "__esModule", {
          value: true
     });
     exports.default = Ember.Route.extend({
          model(params) {
               var url = "https://mtm-server.herokuapp.com/franchises/" + params.id;
               return $.getJSON(url);
          }
     });
});