define("db/routes/random", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model() {
            var url = "https://mtm-server.herokuapp.com/random";
            return $.getJSON(url);
        },

        didInsertElement() {
            debugger;
        },

        actions: {
            regenerate() {
                location.reload();
            }
        }
    });
});