define('db/controllers/colors', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        init() {
            console.log("test");
            this._super();
            window.scrollTo(0, 0);
        },
        actions: {
            scrollTo: function (anchor) {
                $('html, body').animate({
                    scrollTop: $("#" + anchor).offset().top - 80
                }, 50);
            }
        }
    });
});