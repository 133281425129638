define("db/components/random-character", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        names: [],

        didInsertElement() {
            var stub = this.element.parentElement;
            var name = this.element.firstElementChild.getAttribute("name");
            this.names.push(name);

            if (this.names.length == 15) {
                stub.innerText = this.names[0];
                setTimeout(() => {
                    stub.innerText = this.names[1];
                }, 100);
                setTimeout(() => {
                    stub.innerText = this.names[2];
                }, 200);
                setTimeout(() => {
                    stub.innerText = this.names[3];
                }, 300);
                setTimeout(() => {
                    stub.innerText = this.names[4];
                }, 400);
                setTimeout(() => {
                    stub.innerText = this.names[5];
                }, 500);
                setTimeout(() => {
                    stub.innerText = this.names[6];
                }, 600);
                setTimeout(() => {
                    stub.innerText = this.names[7];
                }, 700);
                setTimeout(() => {
                    stub.innerText = this.names[8];
                }, 800);
                setTimeout(() => {
                    stub.innerText = this.names[9];
                }, 900);
                setTimeout(() => {
                    stub.innerText = this.names[10];
                }, 1000);
                setTimeout(() => {
                    stub.innerText = this.names[11];
                }, 1100);
                setTimeout(() => {
                    stub.innerText = this.names[12];
                }, 1200);
                setTimeout(() => {
                    stub.innerText = this.names[13];
                }, 1300);
                setTimeout(() => {
                    stub.innerText = this.names[14];
                }, 1400);
                setTimeout(() => {
                    document.getElementById("final-random-character").style.display = "block";
                }, 1400);
            }
        }
    });
});