define('db/routes/movies/movie', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        setupController: function () {
            this._super(...arguments);
            Ember.run.next(() => {
                const scrollContainer = Ember.$('.long-list-container');
                const scrollDurationMS = 1000;

                scrollContainer.animate({
                    scrollTop: 0
                }, scrollDurationMS);
            });
        },
        model(params) {
            var url = "https://mtm-server.herokuapp.com/movie/" + params.movie;
            return $.getJSON(url);
        }
    });
});