define('db/components/color-swatch', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        didInsertElement() {
            window.scrollTo(0, 0);
            var hex = this.element.firstElementChild.getAttribute("hex");
            var importance = "importance-" + this.element.firstElementChild.getAttribute("importance");
            var colorSwatchContainer = this.element.firstElementChild;
            var colorSwatchCharacterLink = colorSwatchContainer.firstElementChild;
            var colorSwatchContainerParent = colorSwatchContainer.parentElement;

            var fontColor = this.element.firstElementChild.getAttribute("complement");

            colorSwatchContainer.setAttribute("style", "background-color: " + hex + ";");
            colorSwatchContainerParent.setAttribute("class", importance);
            colorSwatchCharacterLink.setAttribute("style", "color: " + fontColor);
        },

        impFilters: 'this.importanceFilters',

        colorShown: Ember.computed('impFilters', function () {
            console.log("Lazy");
            switch (this.importance) {
                case '1':
                    return this.importanceFilters.accents;
                    break;
                case '2':
                    return this.importanceFilters.secondary;
                    break;
                case '3':
                    return this.importanceFilters.primary;
                    break;
            }
        })

    });
});