define('db/helpers/pick', ['exports', 'ember-composable-helpers/helpers/pick'], function (exports, _pick) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _pick.default;
    }
  });
  Object.defineProperty(exports, 'pick', {
    enumerable: true,
    get: function () {
      return _pick.pick;
    }
  });
});