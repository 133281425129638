define('db/components/color-filters', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    primaryColors: Ember.computed('filters', {
      get() {
        const teams = Ember.get(this, 'filters.importance');
        debugger;
        return teams.filterBy('importance', '3');
      }
    })
  });
});