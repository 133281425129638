define('db/components/navigation-bar', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        dropdownVisible: false,

        actions: {
            toggleNavDropDown() {
                this.toggleProperty('dropdownVisible');
            },
            closeDropDown() {
                Ember.set(this, 'dropdownVisible', false);
            }
        }

    });
});